<template>
    <div>
      footer
    </div>
  </template>
  
  <script>
  export default {
    name: 'FooterPage'
  }
  </script>