<template>
    <div class="not-found">
      <h1>404 - Страница не найдена</h1>
      <p>Извините, запрашиваемая страница не существует.</p>
      <router-link to="/">Вернуться на главную</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound'
  }
  </script>
  
  <style scoped>
  .not-found {
    text-align: center;
    padding: 50px;
  }
  
  h1 {
    font-size: 48px;
    color: #333;
  }
  
  p {
    font-size: 18px;
    color: #666;
    margin-bottom: 20px;
  }
  
  a {
    color: #42b983;
    text-decoration: none;
  }
  </style>