import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue' // Убедитесь, что вы создали этот компонент
import axios from 'axios'

async function initRouter() {
  const response = await axios.get('http://172.16.2.49/api/routes')
  console.log(response)
}
initRouter()

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Главная страница',
      description: 'description',
    }
  },
  {
    path: '/about',
    name: 'About',
    component: Home,
    meta: {
      title: 'О нас',
      description: 'description',
    }
  },
  // Добавляем маршрут для страницы 404
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: '404 - Страница не найдена',
      description: 'Запрашиваемая страница не существует',
    }
  }
  // Другие маршруты можно добавить здесь
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Устанавливаем title
  document.title = to.meta.title || ''

  // Устанавливаем description
  let descriptionMeta = document.querySelector('meta[name="description"]')
  if (!descriptionMeta) {
    descriptionMeta = document.createElement('meta')
    descriptionMeta.name = 'description'
    document.head.appendChild(descriptionMeta)
  }
  descriptionMeta.setAttribute('content', to.meta.description || '')

  next()
})

export default router