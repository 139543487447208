<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <div>
    <h1>Главная</h1>
  </div>
</template>

<script>

export default {
  name: 'HomePage'
}
</script>

<style scoped>
/* Добавьте стили, если нужно */
</style>
